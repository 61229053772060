import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { relativeDirectory: { eq: "egitmenler" } }
        sort: { fields: childMarkdownRemark___frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            childMarkdownRemark {
              excerpt(pruneLength: 150)
              fields {
                slug
              }
              frontmatter {
                description
                position
                branches
                avatarImage 
                title
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Eğitmenler" />
      <h1>Eğitmenler</h1>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="main-title mb-4">Eğitmenlerimiz</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  <span className="text-primary font-weight-bold">
                    Carrara Pilates'de{" "}
                  </span>{" "}
                  konusunda uzman eğitmenlerle birlikte çalışın.
                </p>
              </div>
            </div>
          </div>
          <div className="team-list">
            {data.allFile.edges.map(({ node }) => (
              <div className="mt-4 pt-2">
                <div className="team text-center">
                  <Link to={node.childMarkdownRemark.fields.slug}>
                    <div className="position-relative">
                      <img 
                      src={node.childMarkdownRemark.frontmatter.avatarImage}
                       className="img-fluid d-block rounded-pill mx-auto"
                       alt={node.childMarkdownRemark.frontmatter.title}
                      />
                    </div>
                    <div className="content pt-3 pb-3">
                      <h5 className="mb-0">
                        <a href={null} className="name text-dark">
                          {node.childMarkdownRemark.frontmatter.title}
                        </a>
                      </h5>
                      <small className="designation text-muted">
                        {node.childMarkdownRemark.frontmatter.position}
                      </small>
                      <br/>
                      <div className="branch-wrapper">
                    { node.childMarkdownRemark.frontmatter.branches ?
node.childMarkdownRemark.frontmatter.branches.map((tag, i) => [
          <span className='branches' key={i}>
            <span >{tag}</span>
            {i < node.childMarkdownRemark.frontmatter.branches.length - 1 ? ' ' : ''}
          </span>
        ])
      :
      null
      }
      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}
